import qs from "qs";
import api from "../clients/axios";

const resource = "loads"; // Check the route in the backend docs

export default {
  async getAllLoads(data, abortController) {
    const response = await api.get(`${resource}`, {
      params: data.params,
      signal: abortController.signal,
    });
    if (response) {
      return response.data;
    }
    return null;
  },
  async getLoadById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async getLoadByIdLoadDetailsView(id) {
    const response = await api.get(`${resource}/details/${id}`);
    return response.data;
  },
  async createLoad(payload) {
    const response = await api.post(`${resource}`, payload);
    return response.data;
  },
  async updateLoad(data) {
    const response = await api.patch(`${resource}/${data.id}`, data.body);
    return response.data;
  },
  async requoteLoad(data) {
    const response = await api.post(`${resource}/${data.id}/quote`);
    return response.data;
  },
  /// Stops \\\
  async updateStop(data) {
    const response = await api.patch(
      `${resource}/${data.load_id}/stops/${data.stop_id}`,
      data.body
    );
    return response.data;
  },
  /// Accessorials \\\
  async getAllAccessorialsByLoad(loadId) {
    const response = await api.get(`${resource}/${loadId}/accessorials`);
    return response.data;
  },
  async createAccessorialsByLoad(data) {
    const response = await api.post(`${resource}/${data.id}/accessorials`, data.body);
    return response.data;
  },
  async deleteAccessorialsByLoad(data) {
    // eslint-disable-next-line max-len
    const response = await api.delete(`${resource}/${data.load_id}/accessorials`, {
      params: {
        accessorial_id: data.accessorial_id,
      },
    });
    return response.data;
  },
  /// Line Items \\\
  async getLineItemPickList() {
    const response = await api.get(`${resource}/line-item-picklists`);
    return response.data;
  },
  async createLineItem(payload) {
    const response = await api.post(`${resource}/${payload.id}/line-items`, payload.body);
    return response.data;
  },
  async updatedLineItem(payload) {
    const response = await api.patch(
      `${resource}/${payload.id}/line-items/${payload.lineItemId}`,
      payload.body
    );
    return response.data;
  },
  async deleteLineItem(loadId, lineItemId) {
    const response = await api.delete(`${resource}/${loadId}/line-items/${lineItemId}`);
    return response.data;
  },
  /// Tender \\\
  async createTender(payload, abortController) {
    const response = await api.post(`${resource}/${payload.id}/tender`, payload.body, {
      params: payload.params,
      signal: abortController.signal,
    });
    return response;
  },
  async uploadFile(payload) {
    const response = await api.post(`user-docs`, payload);
    return response.data;
  },
  async getLoadsAdvanced(data, abortController) {
    // eslint-disable-next-line max-len
    const response = await api.get(`${resource}/advanced`, {
      params: { ...data },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
      signal: abortController.signal,
    });
    return response.data;
  },
  async updateLoadMarkups(payload) {
    const response = await api.patch(
      `${resource}/${payload.loadId}/carriers/markups`, null, {
        params: payload.markups
      }
    );
    return response.data;
  },
  async updateBillingPO(payload) {
    const response = await api.patch(`${resource}/${payload.id}/billing-po`, payload.body);
    return response.data;
  },
  async updateResidentialAccessorial(loadId) {
    const response = await api.patch(`${resource}/${loadId}/residential`);
    return response;
  },
  async sendBilling(params) {
    const response = await api.post(`melio/send-billing`, params);
    return response.data;
  }
};
